import React from "react";
import CustomForm from "./CustomForm";
import CustomFormEdit from "./CustomFormEdit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  FormControl,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Fab,
  Typography,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Return from "@material-ui/icons/KeyboardReturn";
import api from "../../service/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogEditInput from "./DialogEditInput";

class DialogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: false,
      multi: false,
      form: props.form,
      layout_id: null,
      form_name: "",
      typeForm: null,
      typeFormTable: null,
      formData: [],
      selectOptions: [{ label: null }],
      selectOptionsTable: [{ label: null }],
      checkboxOptions: [{ label: null, type: "checkbox", checked: false }],
      checkboxOptionsTable: [{ label: null, type: "checkbox", checked: false }],
      formDataTable: [],
      users: [],
      users_selected: [],
      openEditInput: false,
      edit: "",
      reviewed: "",
      approved: "",
      date_established: "",
      date_update: "",
      centered: false,
      scale: 100,
    };
    this.signatureRef = this.state.formData
      .filter((form) => form.type == "signature")
      .map(() => React.createRef());
    this.signatureRefTable = this.state.formData.reduce((acc, item) => {
      if (item.columns) {
        item.columns.forEach((column) => {
          if (column.type === "signature") {
            acc.push(React.createRef());
          }
        });
      }
      return acc;
    }, []);
  }
  componentDidMount() {
    if (this.props.form != null) {
      this.getFormsLayout();
      this.getUsersForms();
    }

    this.getUsers();
  }
  componentDidUpdate(prevProps, prevState) {
    this.signatureRef = this.state.formData
      .filter((form) => form.type == "signature")
      .map(() => React.createRef());
    this.signatureRefTable = this.state.formData.reduce((acc, item) => {
      if (item.columns) {
        item.columns.forEach((column) => {
          if (column.type === "signature") {
            acc.push(React.createRef());
          }
        });
      }
      return acc;
    }, []);
  }
  getUsersForms = () => {
    api.get(`showUsers/${this.state.form.id}`).then((resp) => {
      var user = resp.data.data.result;
      this.setState({
        users_selected: user.map((u) => {
          return {
            id: u.id,
            name: u.name,
            email: u.email,
            email_verified_at: u.email_verified_at,
            iduser_type: u.iduser_type,
            created_at: u.created_at,
            updated_at: u.updated_at,
            signatureUrl: u.signatureUrl,
          };
        }),
      });
    });
  };
  getUsers = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
      var enterprise_id = resp_enterprise.data.data.result.id;
      api.get(`/getUsers/${enterprise_id}`).then((users) => {
        this.setState({ users: users.data });
      });
    });
  };
  getFormsLayout = () => {
    this.setState({ form_name: this.state.form.name });
    api
      .get("/layout/type", {
        params: {
          form_id: this.state.form.id,
        },
      })
      .then(async (resp) => {
        await this.setState({
          formData: JSON.parse(resp.data.options),
          layout_id: resp.data.layout,
          date_established: resp.data.date_established,
          date_update: resp.data.date_update,
          edit: resp.data.edit,
          reviewed: resp.data.reviewed,
          approved: resp.data.approved,
        });
      });
  };
  handleChange = (event) => {
    this.setState({ typeForm: event.target.value });
  };
  handleChangeTable = (event) => {
    this.setState({ typeFormTable: event.target.value });
  };
  handleAdd = async () => {
    if (this.state.typeForm == "image") {
      var new_item = {
        type: this.state.typeForm,
      };
    } else {
      var new_item = {
        type: this.state.typeForm,
        component: {
          label: this.state.label,
          type_input: this.state.typeForm == "date" ? "date" : "text",
        },
      };
    }

    if (this.state.typeForm == "checkbox")
      new_item.options = this.state.checkboxOptions;

    if (this.state.typeForm == "video")
      new_item.source = this.state.urlSource;

    if (this.state.typeForm == "audio")
      new_item.source = this.state.urlAudioSource;

    if (this.state.typeForm == "select")
      new_item.options = this.state.selectOptions;

    if (this.state.typeForm == "select-auto")
      new_item.options = this.state.selectOptions;

    if (this.state.typeForm == "table" || this.state.typeForm == "table-info") {
      new_item.columns = this.state.formDataTable;
      new_item.body = [];
    }

    if (this.state.identifier) {
      new_item.identifier = true;
    }
    if (this.state.multi) {
      new_item.multi = true;
    }
    if (this.state.typeForm == "signature")
      new_item.index_signature = this.state.formData.filter(
        (form) => form.type == "signature"
      ).length;

    if (this.state.typeForm == "image") {
      const formData = new FormData();
      formData.append("file", this.state.file);
      try {
        await api
          .post("/storeImage", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            new_item.source = resp.data.data.result;
            new_item.centered = this.state.centered;
            new_item.scale = this.state.scale;
            var items_form = this.state.formData;
            items_form.push(new_item);
            this.setState({
              formDataTable: items_form,
              identifier: false,
              multi: false,
              checkboxOptions: [
                { label: null, type: "checkbox", checked: false },
              ],
              formDataTable: [],
              selectOptions: [{ label: null }],
            });
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      var items_form = this.state.formData;
      items_form.push(new_item);
      this.setState({
        formDataTable: items_form,
        identifier: false,
        multi: false,
        checkboxOptions: [{ label: null, type: "checkbox", checked: false }],
        formDataTable: [],
        selectOptions: [{ label: null }],
      });
    }
  };
  changeCheckboxOptionsTable = (event, index) => {
    var new_options = this.state.checkboxOptionsTable;
    var value_text = event.target.value;
    new_options[index].label = value_text;
    this.setState({ checkboxOptionsTable: new_options });
  };
  changeSelectOptionsTable = (event, index) => {
    var new_options = this.state.selectOptionsTable;
    var value_text = event.target.value;
    new_options[index].label = value_text;
    this.setState({ selectOptionsTable: new_options });
  };
  changeSelectOptions = (event, index) => {
    var new_options = this.state.selectOptions;
    var value_text = event.target.value;
    new_options[index].label = value_text;
    this.setState({ selectOptions: new_options });
  };
  changeCheckboxOptions = (event, index) => {
    var new_options = this.state.checkboxOptions;
    var value_text = event.target.value;
    new_options[index].label = value_text;
    this.setState({ checkboxOptions: new_options });
  };
  addSelectOptionsTable = () => {
    var new_options = this.state.selectOptionsTable;
    new_options.push({ label: null });
    this.setState({ selectOptionsTable: new_options });
  };
  removeSelectOptionsTable = () => {
    var new_options = this.state.selectOptionsTable;
    if (new_options.length > 1) {
      new_options.pop();
      this.setState({ selectOptionsTable: new_options });
    }
  };
  addSelectOptions = () => {
    var new_options = this.state.selectOptions;
    new_options.push({ label: null });
    this.setState({ selectOptions: new_options });
  };
  removeSelectOptions = () => {
    var new_options = this.state.selectOptions;
    if (new_options.length > 1) {
      new_options.pop();
      this.setState({ selectOptions: new_options });
    }
  };
  addCheckboxOptions = () => {
    var new_options = this.state.checkboxOptions;
    new_options.push({ label: null, type: "checkbox", checked: false });
    this.setState({ checkboxOptions: new_options });
  };
  addCheckboxOptionsTable = () => {
    var new_options = this.state.checkboxOptionsTable;
    new_options.push({ label: null, type: "checkbox", checked: false });
    this.setState({ checkboxOptionsTable: new_options });
  };
  addCheckboxObsTable = () => {
    var new_options = this.state.checkboxOptionsTable;
    new_options.push({ label: "Observação", type: "obs" });
    this.setState({ checkboxOptionsTable: new_options });
  };
  removeCheckboxObsTable = () => {
    var new_options = this.state.checkboxOptionsTable;
    if (new_options.length > 1) {
      new_options.pop();
      this.setState({ checkboxOptionsTable: new_options });
    }
  };
  addCheckboxObs = () => {
    var new_options = this.state.checkboxOptions;
    new_options.push({ label: "Observação", type: "obs" });
    this.setState({ checkboxOptions: new_options });
  };
  removeInputCheckbox = () => {
    var new_options = this.state.checkboxOptions;
    if (new_options.length > 1) {
      new_options.pop();
      this.setState({ checkboxOptions: new_options });
    }
  };
  addInputsTable = () => {
    var new_item = {
      type: this.state.typeFormTable,
      component: {
        label: this.state.labelTable,
        type_input: this.state.typeFormTable == "date" ? "date" : "text",
      },
    };
    if (this.state.typeFormTable == "checkbox")
      new_item.options = this.state.checkboxOptionsTable;

    if (this.state.typeFormTable == "select" || this.state.typeFormTable == "select-auto")
      new_item.options = this.state.selectOptionsTable;

    if (this.state.identifier) {
      new_item.identifier = true;
    }

    if (this.state.typeFormTable == "signature")
      new_item.index_signature = this.state.formDataTable.filter(
        (form) => form.type == "signature"
      ).length;

    if (this.state.multi) {
      new_item.multi = true;
    }

    var items_form = this.state.formDataTable;
    items_form.push(new_item);
    console.log(items_form);
    this.setState({
      formDataTable: items_form,
      multi: false,
      identifier: false,
      selectOptionsTable: [{ label: null }],
      checkboxOptionsTable: [{ label: "", type: "checkbox", checked: false }],
    });
  };
  handleSave = () => {
    this.setState({ loading: true });
    if (this.props.form != null) {
      const url = window.location.hostname;
      var url_splt = url.split(".");
      api.get(`/getDomain?domain=${url_splt[0]}`).then((resp_enterprise) => {
        api
          .put(`/forms/${this.props.form.id}`, {
            name: this.state.form_name,
            date_established: this.state.date_established,
            date_update: this.state.date_update,
            edit: this.state.edit,
            reviewed: this.state.reviewed,
            approved: this.state.approved,
            type: this.props.type,
            enterprise_id: resp_enterprise.data.data.result.id,
          })
          .then((resp) => {
            // var form = resp.data.data.result
            if (this.state.layout_id != null) {
              api
                .put(`/layouts/${this.state.layout_id}`, {
                  options: JSON.stringify(this.state.formData),
                  form_id: this.props.form.id,
                })
                .then((resp_layout) => {
                  if (this.state.users_selected.length > 0) {
                    for (
                      let index = 0;
                      index < this.state.users_selected.length;
                      index++
                    ) {
                      const element = this.state.users_selected[index];
                      api.post(`vinculateUser/${this.props.form.id}`, {
                        user_id: element.id,
                      });
                      if (index == this.state.users_selected.length - 1) {
                        this.setState({ loading: false });
                        this.props.handleCloseSave();
                      }
                    }
                  } else {
                    this.setState({ loading: false });
                    this.props.handleCloseSave();
                  }
                });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log(error);
          });
      });
    } else {
      const url = window.location.hostname;
      var url_splt = url.split(".");
      api.get(`/getDomain?domain=${url_splt[0]}`).then((resp_enterprise) => {
        api
          .post("/forms", {
            name: this.state.form_name,
            date_established: this.state.date_established,
            date_update: this.state.date_update,
            edit: this.state.edit,
            reviewed: this.state.reviewed,
            approved: this.state.approved,
            type: this.props.type,
            enterprise_id: resp_enterprise.data.data.result.id,
          })
          .then((resp) => {
            var form = resp.data.data.result;
            api
              .post("/layouts", {
                options: JSON.stringify(this.state.formData),
                form_id: form.id,
              })
              .then((resp_layout) => {
                if (this.state.users_selected.length > 0) {
                  for (
                    let index = 0;
                    index < this.state.users_selected.length;
                    index++
                  ) {
                    const element = this.state.users_selected[index];
                    api.post(`vinculateUser/${form.id}`, {
                      user_id: element.id,
                    });
                    if (index == this.state.users_selected.length - 1) {
                      this.props.handleCloseSave();
                    }
                  }
                } else {
                  this.props.handleCloseSave();
                }
              });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  handleChangeTextForm = (event, index) => {
    var form_datas = this.state.formData;
    form_datas[index].value = event.target.value;
    this.setState({ formData: form_datas });
  };
  handleChangeTextTableForm = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].value = event.target.value;
    this.setState({ formData: form_datas });
  };
  handleAddDatasTableForm = (index) => {
    var form_datas = this.state.formData;
    var colunas = form_datas[index].columns;
    var new_body = [];
    var clear_column = [];
    for (let index_column = 0; index_column < colunas.length; index_column++) {
      const element = colunas[index_column];
      var element_clear = element;
      if (element.type != "checkbox") {
        new_body.push(element?.value);
        element_clear.value = null;
      } else if (element.type == "checkbox") {
        var element_checkbox = element.options
          .filter((check) => check.checked == true)
          .map((check) => check.label)
          .join(",");
        if (element.options.filter((check) => check.type == "obs").length > 0) {
          var element_obs = element.options
            .filter((check) => check.type == "obs")
            .map((check) => check.value)
            .join();
          element_checkbox = element_checkbox.concat(element_obs);
        }
        new_body.push(element_checkbox);
        element_clear.options = element.options.map((check) => {
          return { label: check.label, type: check.type, checked: false };
        });
      }
      clear_column.push(element_clear);
      if (index_column == colunas.length - 1) {
        form_datas[index].body.push(new_body);
        form_datas[index].columns = clear_column;
        this.setState({ formData: form_datas });
      }
    }
  };
  handleChangeCheckboxFormMulti = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].options[index_component].checked = event.target.checked;
    // for (let index_for = 0; index_for < form_datas[index].options.length; index_for++) {
    //     if (index_for != index_component) {
    //         form_datas[index].options[index_for].checked = false
    //     }
    // }
    this.setState({ formData: form_datas });
  };
  handleChangeCheckboxForm = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].options[index_component].checked = event.target.checked;
    for (
      let index_for = 0;
      index_for < form_datas[index].options.length;
      index_for++
    ) {
      if (index_for != index_component) {
        form_datas[index].options[index_for].checked = false;
      }
    }
    this.setState({ formData: form_datas });
  };
  handleChangeCheckboxFormTableMulti = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].checked = event.target.checked;
    this.setState({ formData: form_datas });
  };
  handleChangeCheckboxFormTable = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].checked = event.target.checked;
    for (
      let index_for = 0;
      index_for < form_datas[index].columns[index_component].options.length;
      index_for++
    ) {
      if (index_for != index_component) {
        form_datas[index].columns[index_component].options[
          index_for
        ].checked = false;
      }
    }
    this.setState({ formData: form_datas });
  };
  handleChangeSelectFormTable = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].value = event.target.value;
    this.setState({ formData: form_datas });
  };
  handleChangeCheckboxObs = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].options[index_component].value = event.target.value;
    this.setState({ formData: form_datas });
  };
  handleChangeCheckboxObsTable = (
    event,
    index,
    index_component,
    index_component_checkbox
  ) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].options[
      index_component_checkbox
    ].value = event.target.value;
    this.setState({ formData: form_datas });
  };
  handleSelectOptions = (event, index) => {
    var form_datas = this.state.formData;
    var value_text = event.target.value;
    form_datas[index].value = value_text;
    this.setState({ formData: form_datas });
  };
  deleteBodyTable = (index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].body = form_datas[index].body.filter(
      (body, index) => index != index_component
    );
    this.setState({ formData: form_datas });
  };
  handleDeleteLast = () => {
    var form_datas = this.state.formData;
    form_datas.pop();
    this.setState({ formData: form_datas });
  };
  handleFileSelect = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  handleSubmitFile = async (event) => {
    const formData = new FormData();
    formData.append("file", this.state.file);
    try {
      await api.post("/storeImage", {
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (error) {
      console.log(error);
    }
  };
  handleSignatureTable = (index, index_component, index_signature) => {
    const signatureData =
      this.signatureRefTable[index_signature].current.toDataURL();
  };
  handleSignature = (index_signature) => {
    const signatureData =
      this.signatureRef[index_signature].current.toDataURL();
  };
  handleClearSignature = (index_signature) => {
    this.signatureRef[index_signature].current.clear();
  };
  handleClearSignatureTable = (index_signature) => {
    this.signatureRefTable[index_signature].current.clear();
  };
  handleSelectFunc = (index, value) => {
    var form_datas = this.state.formData;
    form_datas[index].value = value;
    this.setState({ formData: form_datas });
  };
  handleSelectFuncFormTable = (event, index, index_component) => {
    var form_datas = this.state.formData;
    form_datas[index].columns[index_component].value = event.target.value;
    this.setState({ formData: form_datas });
  };

  deleteComponent = (index) => {
    var form_datas = this.state.formData;
    form_datas.splice(index, 1);
    this.setState({ formData: form_datas });
  };
  updateComponent = (index) => {
    this.setState({ openEditInput: true, indexSelected: index });
  };
  updateNameInput = (label) => {
    var form_datas = this.state.formData;
    form_datas[this.state.indexSelected].component.label = label;
    this.setState({
      formData: form_datas,
      indexSelected: null,
      openEditInput: false,
    });
  };
  handleDate = (event, name) => {
    this.setState({ [name]: event.target.value });
  };
  handleText = (event, name) => {
    this.setState({ [name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.openEditInput && (
          <DialogEditInput
            updateNameInput={this.updateNameInput}
            close={() => this.setState({ openEditInput: false })}
          />
        )}
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={true}
          onClose={this.props.handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            Criação de formulário
          </DialogTitle>

          <DialogContent>
            {this.props.form != null && (
              <div>
                <TextField
                  id="standard-basic"
                  disabled
                  label={"Código"}
                  value={this.props.form.id}
                  variant="outlined"
                  className={classes.textId}
                  margin="normal"
                />
                <TextField
                  id="standard-basic"
                  disabled
                  label={"Código Layout"}
                  value={this.state.layout_id}
                  variant="outlined"
                  className={classes.textId}
                  margin="normal"
                />
              </div>
            )}
            <TextField
              label="Nome do formulário"
              value={this.state.form_name}
              onChange={(event) =>
                this.setState({ form_name: event.target.value })
              }
              style={{ width: "70%", margin: "5px" }}
            />
            <div>
              <TextField
                id="date"
                label={"Data estabelecido"}
                value={this.state.date_established}
                variant="outlined"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.handleDate(e, "date_established")}
              />
              <TextField
                id="date"
                label={"Data Atualizado"}
                value={this.state.date_update}
                variant="outlined"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => this.handleDate(e, "date_update")}
              />
              <TextField
                id="standard-basic"
                label="Editado"
                value={this.state.edit}
                variant="outlined"
                className={classes.textField}
                margin="normal"
                onChange={(e) => this.handleText(e, "edit")}
              />
              <TextField
                id="standard-basic"
                label="Revisado"
                value={this.state.reviewed}
                variant="outlined"
                className={classes.textField}
                margin="normal"
                onChange={(e) => this.handleText(e, "reviewed")}
              />
              <TextField
                id="standard-basic"
                label="Aprovado"
                value={this.state.approved}
                variant="outlined"
                className={classes.textField}
                margin="normal"
                onChange={(e) => this.handleText(e, "approved")}
              />
            </div>
            <div className={classes.containerAutocomplete}>
              <Autocomplete
                multiple
                id="combo-user"
                options={this.state.users}
                getOptionLabel={(option) => option.name}
                style={{ width: 300, margin: 5 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Acesso de usuários"
                    placeholder="Acesso de usuários"
                  />
                )}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  this.setState({ users_selected: newValue });
                }}
                value={this.state.users_selected}
              />
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Tipo de dados
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.typeForm}
                  onChange={this.handleChange}
                >
                  <MenuItem value={"input"}>Input Texto</MenuItem>
                  <MenuItem value={"input-full"}>Input Texto Full</MenuItem>
                  <MenuItem value={"date"}>Input Data</MenuItem>
                  <MenuItem value={"checkbox"}>CheckBox</MenuItem>
                  <MenuItem value={"textarea"}>Input Texto Grande</MenuItem>
                  <MenuItem value={"table"}>Tabela</MenuItem>
                  {/* <MenuItem value={'table-info'}>Tabela Informativa</MenuItem> */}
                  <MenuItem value={"text-info"}>Texto Informativo</MenuItem>
                  {/* <MenuItem value={'file'}>Arquivos</MenuItem> */}
                  <MenuItem value={"select"}>Seletor</MenuItem>
                  <MenuItem value={"image"}>Imagem</MenuItem>
                  <MenuItem value={"signature"}>Assinatura</MenuItem>
                  <MenuItem value={"select-func"}>
                    Seleção de Funcionários
                  </MenuItem>
                  <MenuItem value={"title"}>Título</MenuItem>
                  <MenuItem value={"title-black"}>Título Black Bar</MenuItem>
                  <MenuItem value={"video"}>Video</MenuItem>
                  <MenuItem value={"audio"}>Audio</MenuItem>
                  <MenuItem value={"select-auto"}>Select Flexivel</MenuItem>
                </Select>
              </FormControl>
              <Paper className={classes.container}>
                <Typography variant="h6">Configurações</Typography>
                <Typography variant="subtitle1">
                  Adicione uma lista de opções a serem escolhidas
                </Typography>
                {this.state.typeForm != null &&
                  this.state.typeForm != "text-info" &&
                  this.state.typeForm != "image" && (
                    <div>
                      <TextField
                        label={
                          this.state.typeForm == "table"
                            ? "Título da tabela"
                            : "Título do campo"
                        }
                        value={this.state.label}
                        onChange={(event) =>
                          this.setState({ label: event.target.value })
                        }
                      />

                      {this.state.typeForm != "table" && <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.identifier}
                            onChange={(e) =>
                              this.setState({ identifier: e.target.checked })
                            }
                            name="Identificador"
                          />
                        }
                        label="Identificador"
                      />}
                    </div>
                  )}
                {this.state.typeForm == "video" &&
                  <TextField
                    label={"url"}
                    fullWidth
                    value={this.state.urlSource}
                    onChange={(event) =>
                      this.setState({ urlSource: event.target.value })
                    }
                  />
                }
                {this.state.typeForm == "audio" &&
                  <TextField
                    label={"url"}
                    fullWidth
                    value={this.state.urlAudioSource}
                    onChange={(event) =>
                      this.setState({ urlAudioSource: event.target.value })
                    }
                  />
                }
                {this.state.typeForm == "text-info" && (
                  <TextareaAutosize
                    rows={5}
                    placeholder="Texto Informativo"
                    className={classes.textArea}
                    value={this.state.label}
                    onChange={(event) =>
                      this.setState({ label: event.target.value })
                    }
                  />
                )}
                {this.state.typeForm == "checkbox" && (
                  <div style={{ padding: 20 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.multi}
                          onChange={(e) =>
                            this.setState({ multi: e.target.checked })
                          }
                          name="Seleção múltipla"
                        />
                      }
                      label="Seleção múltipla"
                    />
                    {this.state.checkboxOptions.map((checkbox, index) => (
                      <TextField
                        label={`${index}`}
                        value={checkbox.label}
                        onChange={(event) =>
                          this.changeCheckboxOptions(event, index)
                        }
                        style={{ width: "150px", margin: "5px" }}
                      />
                    ))}
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addCheckboxOptions()}
                        endIcon={<AddIcon>opção</AddIcon>}
                      >
                        Adicionar opção
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addCheckboxObs()}
                        endIcon={<AddIcon>opção</AddIcon>}
                      >
                        Adicionar observação
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="red"
                        className={classes.button}
                        onClick={() => this.removeInputCheckbox()}
                        endIcon={<DeleteIcon>opção</DeleteIcon>}
                      >
                        Remover campo
                      </Button>
                    </div>
                  </div>
                )}
                {(this.state.typeForm == "select" || this.state.typeForm == "select-auto") && (
                  <div style={{ padding: 20 }}>
                    {this.state.selectOptions.map((item, index) => (
                      <TextField
                        label={`${index}`}
                        value={item.label}
                        onChange={(event) =>
                          this.changeSelectOptions(event, index)
                        }
                        style={{ width: "150px", margin: "5px" }}
                      />
                    ))}
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addSelectOptions()}
                        endIcon={<AddIcon>opção</AddIcon>}
                      >
                        Adicionar opção
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        color="red"
                        className={classes.button}
                        onClick={() => this.removeSelectOptions()}
                        endIcon={<DeleteIcon>opção</DeleteIcon>}
                      >
                        Remover campo
                      </Button>
                    </div>
                  </div>
                )}
                {(this.state.typeForm == "table" ||
                  this.state.typeForm == "table-info") && (
                    <Paper className={classes.container}>
                      <Typography variant="subtitle1">
                        Adicione as informações que estarão na tabela e seus
                        respectivos dados de inserção
                      </Typography>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Tipo de dados
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.typeFormTable}
                          onChange={this.handleChangeTable}
                        >
                          <MenuItem value={"input"}>Input Texto</MenuItem>
                          <MenuItem value={"date"}>Input Data</MenuItem>
                          <MenuItem value={"checkbox"}>CheckBox</MenuItem>
                          <MenuItem value={"textarea"}>
                            Input Texto Grande
                          </MenuItem>
                          <MenuItem value={"select"}>Seletor</MenuItem>
                          <MenuItem value={"signature"}>Assinatura</MenuItem>
                          <MenuItem value={"select-func"}>
                            Seletor de Funcionários
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {this.state.typeFormTable != null && (
                        <div className={classes.actionsTable}>
                          <TextField
                            label="Título do campo da tabela"
                            value={this.state.labelTable}
                            onChange={(event) =>
                              this.setState({ labelTable: event.target.value })
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.identifier}
                                onChange={(e) =>
                                  this.setState({ identifier: e.target.checked })
                                }
                                name="Identificador"
                              />
                            }
                            label="Identificador"
                          />
                        </div>
                      )}
                      {this.state.typeFormTable == "checkbox" && (
                        <div style={{ padding: 20 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.multi}
                                onChange={(e) =>
                                  this.setState({ multi: e.target.checked })
                                }
                                name="Seleção múltipla"
                              />
                            }
                            label="Seleção múltipla"
                          />
                          {this.state.checkboxOptionsTable.map(
                            (checkbox, index) => (
                              <TextField
                                label={`${index}`}
                                value={checkbox.label}
                                onChange={(event) =>
                                  this.changeCheckboxOptionsTable(event, index)
                                }
                                style={{ width: "150px", margin: "5px" }}
                              />
                            )
                          )}
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.button}
                              onClick={() => this.addCheckboxOptionsTable()}
                              endIcon={<AddIcon>opção</AddIcon>}
                            >
                              Adicionar opção
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.button}
                              onClick={() => this.addCheckboxObsTable()}
                              endIcon={<AddIcon>opção</AddIcon>}
                            >
                              Adicionar observação
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              color="red"
                              className={classes.button}
                              onClick={() => this.removeCheckboxObsTable()}
                              endIcon={<DeleteIcon>opção</DeleteIcon>}
                            >
                              Remover campo
                            </Button>
                          </div>
                        </div>
                      )}
                      {this.state.typeFormTable == "select" && (
                        <div style={{ padding: 20 }}>
                          {this.state.selectOptionsTable.map((item, index) => (
                            <TextField
                              label={`${index}`}
                              value={item.label}
                              onChange={(event) =>
                                this.changeSelectOptionsTable(event, index)
                              }
                              style={{ width: "150px", margin: "5px" }}
                            />
                          ))}
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.button}
                              onClick={() => this.addSelectOptionsTable()}
                              endIcon={<AddIcon>opção</AddIcon>}
                            >
                              Adicionar opção
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              color="red"
                              className={classes.button}
                              onClick={() => this.removeSelectOptionsTable()}
                              endIcon={<DeleteIcon>opção</DeleteIcon>}
                            >
                              Remover campo
                            </Button>
                          </div>
                        </div>
                      )}
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addInputsTable()}
                        endIcon={<AddIcon>opção</AddIcon>}
                      >
                        Adicionar campo
                      </Button>
                      <Typography variant="subtitle1" align="center">
                        Está é uma prévia dos dados da tabela
                      </Typography>
                      <Paper className={classes.paperFormData}>
                        <CustomForm
                          fields={this.state.formDataTable}
                          handleChangeTextForm={this.handleChangeTextForm}
                          handleChangeTextTableForm={
                            this.handleChangeTextTableForm
                          }
                          handleAddDatasTableForm={this.handleAddDatasTableForm}
                          handleChangeCheckboxForm={this.handleChangeCheckboxForm}
                          handleChangeCheckboxFormMulti={
                            this.handleChangeCheckboxFormMulti
                          }
                          handleChangeCheckboxFormTable={
                            this.handleChangeCheckboxFormTable
                          }
                          handleChangeCheckboxFormTableMulti={
                            this.handleChangeCheckboxFormTableMulti
                          }
                          handleChangeCheckboxObs={this.handleChangeCheckboxObs}
                          handleSelectOptions={this.handleSelectOptions}
                          handleChangeSelectFormTable={
                            this.handleChangeSelectFormTable
                          }
                          handleSelectFuncFormTable={
                            this.handleSelectFuncFormTable
                          }
                          deleteBodyTable={this.deleteBodyTable}
                          handleChangeCheckboxObsTable={
                            this.handleChangeCheckboxObsTable
                          }
                          signatureRef={this.signatureRef}
                          signatureRefTable={this.signatureRefTable}
                          handleSignature={this.handleSignature}
                          handleClearSignature={this.handleClearSignature}
                          handleSignatureTable={this.handleSignatureTable}
                          handleClearSignatureTable={
                            this.handleClearSignatureTable
                          }
                          handleSelectFunc={this.handleSelectFunc}
                          users={this.state.users}
                        />
                      </Paper>
                    </Paper>
                  )}
                {this.state.typeForm == "image" && (
                  <>
                    <TextField
                      type="file"
                      placeholder="Imagem"
                      className={classes.textArea}
                      onChange={this.handleFileSelect}
                    />
                    <div className={classes.imageOptions}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.centered}
                            onChange={(e) =>
                              this.setState({ centered: e.target.checked })
                            }
                            name="Centralizar imagem"
                          />
                        }
                        label="Centralizar imagem"
                      />
                      <div>
                        <InputLabel className={classes.text}>
                          Tamanho da imagem
                        </InputLabel>
                        <Select
                          className={classes.imageScale}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.scale}
                          label="Tamanho da imagem"
                          onChange={(e) =>
                            this.setState({ scale: e.target.value })
                          }
                        >
                          <MenuItem value={25}>25%</MenuItem>
                          <MenuItem value={50}>50%</MenuItem>
                          <MenuItem value={75}>75%</MenuItem>
                          <MenuItem value={100}>100%</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </>
                )}
                <div className={classes.containerAdd}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={this.handleAdd}
                  >
                    <AddIcon />
                  </Fab>
                </div>
              </Paper>
            </div>
            <Typography variant="subtitle1" align="center">
              Está é uma prévia do formulário personalizado
            </Typography>
            <div className={classes.containerAdd}>
              <Fab
                color="primary"
                aria-label="add"
                onClick={this.handleDeleteLast}
              >
                <Return />
              </Fab>
            </div>
            <div className={classes.containerFormData}>
              <Paper className={classes.paperFormData}>
                <CustomFormEdit
                  fields={this.state.formData}
                  handleChangeTextForm={this.handleChangeTextForm}
                  handleChangeTextTableForm={this.handleChangeTextTableForm}
                  handleAddDatasTableForm={this.handleAddDatasTableForm}
                  handleChangeCheckboxForm={this.handleChangeCheckboxForm}
                  handleChangeCheckboxFormMulti={
                    this.handleChangeCheckboxFormMulti
                  }
                  handleChangeCheckboxFormTable={
                    this.handleChangeCheckboxFormTable
                  }
                  handleChangeCheckboxFormTableMulti={
                    this.handleChangeCheckboxFormTableMulti
                  }
                  handleChangeCheckboxObs={this.handleChangeCheckboxObs}
                  handleSelectOptions={this.handleSelectOptions}
                  handleChangeSelectFormTable={this.handleChangeSelectFormTable}
                  deleteBodyTable={this.deleteBodyTable}
                  handleChangeCheckboxObsTable={
                    this.handleChangeCheckboxObsTable
                  }
                  signatureRef={this.signatureRef}
                  signatureRefTable={this.signatureRefTable}
                  handleSignature={this.handleSignature}
                  handleClearSignature={this.handleClearSignature}
                  handleSignatureTable={this.handleSignatureTable}
                  handleClearSignatureTable={this.handleClearSignatureTable}
                  handleSelectFunc={this.handleSelectFunc}
                  deleteComponent={this.deleteComponent}
                  updateComponent={this.updateComponent}
                  users={this.state.users}
                />
              </Paper>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Fechar
            </Button>
            <Button
              onClick={this.handleSave}
              disabled={this.state.loading}
              color="primary"
            >
              {this.state.loading && (
                <CircularProgress
                  size={28}
                  style={{ color: "#3f51b5", marginRight: "8px" }}
                />
              )}
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const styles = (theme) => ({
  imageOptions: {
    display: "flex",
    gap: "2rem",
    marginTop: "2rem",
  },
  imageScale: {
    minWidth: 200,
  },
  formControl: {
    margin: "5px",
    minWidth: 200,
  },
  containerAdd: {
    justifyContent: "end",
    display: "flex",
  },
  container: {
    width: "90%",
    padding: 20,
    margin: "5px",
  },
  containerFormData: {
    width: "100%",
    // margin: '-32px -48px 0 0',
    // margin: '10px',
    background: " #e9f1ff",
    borderRadius: "0 12px 12px 0",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  paperFormData: {
    width: "70%",
    padding: "16px 48px",
    margin: "15px",
    wordBreak: "break-word",
  },
  button: {
    margin: "5px",
  },
  actionsTable: {
    display: "flex",
    alignItems: "baseline",
  },
  textArea: {
    width: "100%",
  },
  containerAutocomplete: {
    display: "flex",
  },
  textField: {
    margin: theme.spacing(1),
  },
});

export default withStyles(styles)(DialogForm);
